import React, { useEffect, useState } from "react";
import {
  makeStyles,
  useTheme,
  PreambleTheme,
} from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Chip from "@civicplus/preamble-ui/lib/Chip";
import { EventMeeting } from "types/types.event";
import { formatDate } from "@civicplus/preamble-ui/lib/Utilities/DateHelper";
import { removeStringDateTimeZone } from "utils";
import SkeletonLoader from "@civicplus/preamble-ui/lib/SkeletonLoader";
import LocationOn from "@material-ui/icons/LocationOn";
import VideoLiveIcon from "@material-ui/icons/FiberManualRecord";
import { SearchResult } from "types/types.search";
import HtmlParser from "html-react-parser";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "app/store";

const rowQuerySelector = "#Event-list [data-date]";

const dateDetailsWidth = 180;

const useStyles = makeStyles<PreambleTheme, EventListRowProps>(theme => ({
  dateDetails: {
    "@container eventListContainer (width > 800px)": {
      maxWidth: dateDetailsWidth,
    },
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderTopLeftRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5),
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexWrap: "wrap",
    },
    [`@container eventListContainer (max-width: 800px)`]: {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  otherDetails: {
    "@container eventListContainer (width > 800px)": {
      maxWidth: `calc(100% - ${dateDetailsWidth}px)`,
    },
  },
  eventsContainer: {
    "@container eventListContainer (max-width: 800px)": {
      flexDirection: "column",
    },
  },
  dateDetailsBorder: { borderBottomLeftRadius: theme.shape.borderRadius },
  renderedDate: { textTransform: "uppercase" },
  renderedDateColor: { color: theme.palette.primary.contrastText },
  eventDetails: {
    padding: theme.spacing(2, 1, 2, 2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.25),
    },
  },
  eventTitle: {
    padding: theme.spacing(0, 0, 0.5, 0),
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  locationIcon: {
    margin: theme.spacing(0, 1, 0, 0),
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(0.5),
    },
  },
  notice: {
    fontSize: theme.typography.caption.fontSize,
    color: "red",
  },
  rowButton: {
    width: theme.spacing(6),
  },
  embedTextColor: {
    color: "inherit",
  },
  liveIcon: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
  },
  eventRowListWrapper: {
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    "& .prmbl-button": {
      textTransform: "none",
      letterSpacing: "normal",
    },
    "@container eventListContainer (max-width: 800px)": {
      flexDirection: "column",
    },
  },
  chipRow: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
}));

interface EventListRowProps extends Partial<EventMeeting> {
  loading?: boolean;
  id?: EventMeeting["id"];
  searchResults?: SearchResult;
}

const EventListRow: React.FunctionComponent<EventListRowProps> = props => {
  const {
    id,
    startDateTime,
    categoryName,
    eventName,
    eventNotice,
    showEventNoticePreview,
    liveIsCurrentlyStreaming,
    streamingStatus,
    eventLocation,
    loading,
    searchResults,
  } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const dateContainerRef = React.useRef<HTMLDivElement>(null);
  const [isDesktop, setIsDesktop] = useState<boolean>();
  const showEventNotice = showEventNoticePreview && eventNotice;
  const portalCustomization = useSelector(
    (state: RootState) => state.portalCustomization
  );

  let renderedDay: React.ReactNode = (
    <SkeletonLoader
      color={theme.palette.primary.light}
      width={theme.spacing(6)}
    />
  );
  let renderedMonth: React.ReactNode = (
    <SkeletonLoader
      color={theme.palette.primary.light}
      width={theme.spacing(isDesktop ? 12 : 17)}
    />
  );
  let renderedTime: React.ReactNode = (
    <SkeletonLoader
      color={theme.palette.primary.light}
      width={theme.spacing(12)}
    />
  );
  let renderedYear: React.ReactNode = isDesktop ? (
    <SkeletonLoader
      color={theme.palette.primary.light}
      width={theme.spacing(8)}
    />
  ) : (
    <></>
  );
  if (!loading && startDateTime) {
    renderedDay = formatDate(removeStringDateTimeZone(startDateTime), "EEEE");
    renderedMonth = formatDate(
      removeStringDateTimeZone(startDateTime),
      "MMM d, "
    );
    renderedYear = formatDate(removeStringDateTimeZone(startDateTime), "yyyy");
    renderedTime =
      (isDesktop ? "" : "at ") +
      `${formatDate(
        removeStringDateTimeZone(startDateTime),
        "h:mm aa"
      )} ${portalCustomization?.abbreviatedTimeZone}`;
  }

  const renderedDateTime = startDateTime
    ? formatDate(
        removeStringDateTimeZone(startDateTime),
        "EEEE, MMM. dd, yyyy h:mm aa"
      )
    : undefined;

  //Dynamically gets container size of date section and sets isMobile
  useEffect(() => {
    function handleWindowResize() {
      dateContainerRef?.current &&
        setIsDesktop(
          dateContainerRef.current?.clientWidth !==
            dateContainerRef.current?.parentElement?.clientWidth
        );
    }

    if (!isDesktop && dateContainerRef.current) {
      setIsDesktop(
        dateContainerRef.current?.clientWidth !==
          dateContainerRef.current?.parentElement?.clientWidth
      );
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isDesktop]);

  return (
    <Grid
      container={true}
      className={classes.eventsContainer}
      id={`eventListRow-${id}`}
      data-date={startDateTime}
      aria-labelledby={`eventListRow-${id}-title`}
      aria-describedby={`eventListRow-${id}-datetime`}
      data-testid="row"
    >
      <Grid
        item={true}
        xs={true}
        className={classNames(classes.dateDetails, {
          [classes.dateDetailsBorder]: !searchResults,
        })}
        data-testid="dateDetails"
        innerRef={dateContainerRef}
      >
        <Grid
          id={`eventListRow-${id}-datetime`}
          container={true}
          aria-label={`${categoryName} event on ${renderedDateTime}`}
        >
          {!isDesktop ? (
            <Grid item={true} xs={12}>
              <Typography variant="body2">
                {renderedDay} {renderedMonth} {renderedYear} {renderedTime}{" "}
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item={true} xs={12}>
                <Typography variant="caption">{renderedDay}</Typography>
              </Grid>
              <Grid item={true} xs={12} className={classes.renderedDate}>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.renderedDateColor}
                >
                  {renderedMonth}
                  <br />
                  {renderedYear}
                </Typography>
              </Grid>
              <Grid item={true} xs={12}>
                <Typography variant="body2" className={classes.embedTextColor}>
                  {renderedTime}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.otherDetails} item={true} xs={true}>
        <Grid
          container={true}
          className={classes.eventDetails}
          data-testid="eventDetails"
        >
          <Grid item={true} xs={12} className={classes.eventTitle}>
            <Grid container={true} spacing={1} alignItems="center">
              <Grid item={true}>
                <Typography
                  id={`eventListRow-${id}-title`}
                  variant={isDesktop ? "h6" : "subtitle1"}
                  component="h3"
                  className={classes.embedTextColor}
                >
                  {loading ? (
                    <SkeletonLoader width={theme.spacing(22)} />
                  ) : eventName ? (
                    HtmlParser(`${eventName}`)
                  ) : (
                    `${categoryName} Event`
                  )}
                </Typography>
              </Grid>
              {liveIsCurrentlyStreaming && (
                <Grid item={true}>
                  <Typography
                    variant="subtitle2"
                    align="inherit"
                    className={classes.liveIcon}
                  >
                    {streamingStatus === 2 ? (
                      <>
                        <VideoLiveIcon
                          data-testid="live"
                          color="error"
                          fontSize="inherit"
                        />
                        live
                      </>
                    ) : streamingStatus === 1 ? (
                      <>
                        <VideoLiveIcon
                          data-testid="paused"
                          color="action"
                          fontSize="inherit"
                        />
                        paused
                      </>
                    ) : null}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          {eventLocation && (
            <Grid item={true} xs={12}>
              <Grid container={true} wrap="nowrap">
                {eventLocation?.address1 ? (
                  <LocationOn
                    color="disabled"
                    fontSize="small"
                    className={classes.locationIcon}
                  />
                ) : null}
                <Typography variant={isDesktop ? undefined : "subtitle2"}>
                  {eventLocation?.address1
                    ? HtmlParser(`${eventLocation.address1} `)
                    : ""}
                  {eventLocation?.address2
                    ? HtmlParser(`${eventLocation.address2} `)
                    : ""}
                  {eventLocation?.city
                    ? HtmlParser(`${eventLocation.city}, `)
                    : ""}
                  {eventLocation?.state
                    ? HtmlParser(`${eventLocation.state} `)
                    : ""}
                  {eventLocation?.zipCode
                    ? HtmlParser(`${eventLocation.zipCode}`)
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          )}
          {(loading || showEventNotice) && (
            <Grid item={true} xs={12} md={10}>
              {(eventNotice || loading) && (
                <Typography
                  variant={isDesktop ? "subtitle1" : "subtitle2"}
                  title="Notice"
                  component="p"
                  className={classes.embedTextColor}
                >
                  {loading ? (
                    <SkeletonLoader width={theme.spacing(32)} />
                  ) : (
                    eventNotice
                  )}
                </Typography>
              )}
            </Grid>
          )}
          <Grid className={classes.chipRow} item={true} xs={8} md={10}>
            {categoryName || loading ? (
              <Chip
                label={
                  loading ? (
                    <SkeletonLoader
                      width={theme.spacing(10)}
                      color={theme.palette.primary.light}
                    />
                  ) : (
                    categoryName
                  )
                }
              />
            ) : undefined}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(EventListRow);
export { rowQuerySelector };
